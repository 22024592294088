<template>
  <div class="account">
    <div class="profilePhoto pt-r">
      <template v-if="userInfo.sysCompany">
        <img class="img" v-if="userInfo.sysCompany.signInformationDefinition &&userInfo.sysCompany.signInformationDefinition.logoUrl" 
          :src="userInfo.sysCompany.signInformationDefinition.logoUrl" />
        <img class="img" :src="require('@/assets/img/general/enterprise.png')" v-else />
      </template>
      <img :src="userInfo.avatar" class="img" v-else-if="userInfo.avatar" />
      <img class="img" :src="require('@/assets/img/general/user.png')" v-else />
      <template v-if="userInfo.sysCompany">
        <realname class="pt-a realname" is="true" CSStype="5" type="2" v-if="userInfo.sysCompany.isauthentication == 1">
        </realname>
        <realname class="pt-a realname" CSStype="4" type="2" v-else></realname>
      </template>
    </div>
    <div class="mt-16 fs-22 fw-b">
      {{ userInfo.sysCompany ? userInfo.sysCompany.companyName : DesChname }}
      <el-popover :visible="visible" placement="bottom" :width="340" trigger="click"
        popper-class="el_popper_none_padding">
        <switchCenter @newCompany="clickUserCheck(() => { unref(startRef).add() })" ref="switchref"></switchCenter>
        <template #reference>
          <el-image class="w-16 h-16 cu-p" :src="require('@/assets/img/home/switch.png')" />
        </template>
      </el-popover>
    </div>
    <div class="mt-7 fs-14" v-if="userInfo.sysCompany">
      {{ DesChname }} {{ DesPhone }}
    </div>
    <div class="mt-7 fs-14" v-else>{{ DesPhone }}</div>
    <div class="rolebox dp-f fw-w jc-c mt-5 ai-c">
      <realname class="mr-5 mb-5" :is="userInfo.isCertification == 1" CSStype="3" type="1"></realname>
      <div class="role fs-12" v-for="(el, ind) in userInfo.sysCompany.roleList" :key="ind" v-if="userInfo.sysCompany">
        {{ el.roleName }}
      </div>
    </div>
    <div class="right fs-14 cu-p" @click="router_push_name('operation_myseal')" v-if="userInfo.sysCompany">
      <el-image class="user" :src="require('@/assets/img/home/my.png')" />
      我的印章
    </div>
    <start ref="startRef" @Emit="unref(switchref).getCompanyall(), resetUserInfo(() => { })"></start>
  </div>
</template>

<script setup>
import start from "@/views/user/components/userModule/myCompany/start.vue"; //创建新企业
import { clickUserCheck } from "@/utils/base/realname.js"
import { router_push_name } from "@/utils/server/router";
import { ref, unref, reactive, computed } from "vue";
import {
  chnameDesensitization,
  phoneDesensitization,
} from "@/hooks/desensitization.js";
import realname from "@/components/icon/realname.vue";
import switchCenter from "@/components/dialog/switch.vue";
const switchref=ref()
const userInfo = computed(() => {
  return JSON.parse(window.sessionStorage.user).user_info;
});
// isauthentication 企业是否实名 1实名
const DesChname = chnameDesensitization(
  JSON.parse(window.sessionStorage.user).user_info.chnname
);
const DesPhone = phoneDesensitization(
  JSON.parse(window.sessionStorage.user).user_info.phone
);
const startRef = ref();
</script>

<style lang="scss" scoped>
.account {
  background-color: #fff;
  width: 458px;
  text-align: center;
  padding-top: 20px;
  position: relative;

  .realname {
    bottom: 0px;
    left: calc(50% - 33px);
  }

  .profilePhoto {
    .img {
      width: 81px;
      height: 81px;
      border-radius: 50%;
    }
  }

  .rolebox {
    height: 50px;
    overflow: hidden;
  }

  .role {
    width: fit-content;
    padding: 3px 8px;
    color: var(--active-theme);
    background-color: var(--active-alpha);
    border-radius: 4px;
    margin: 0 5px;
    margin-bottom: 5px;
  }

  .right {
    border-radius: 15px;
    background-color: var(--active-theme);
    display: flex;
    align-items: center;
    position: absolute;
    top: 22px;
    right: 20px;
    padding: 4px 12px;
    color: #fff;

    .user {
      width: 20px;
      height: 20px;
      margin-right: 3px;
    }
  }
}
</style>