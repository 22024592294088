<template>
  <div class="switchCenter autobox" v-loading="loading" element-loading-text="切换企业中。。。"
			:element-loading-spinner="config.loading.spinner">
    <!-- {{ userInfo.sysCompany.isAuthentication }} -->
    <div class="switchItem cu-p" :class="userInfo.companyId ? '' : 'switchItemac'" v-if="props.type == 1" @click="switchClick()">
      <div class="dp-f ai-c mb-6">
        <div class="mr-6">{{ userInfo.chnname }}</div>
        <realname :is="userInfo.isCertification == 1" CSStype="3"></realname>
      </div>
      <div class="label">个人账号</div>
    </div>
    <div class="transition dp-f jc-c ai-c mb-14 mt-14" v-if="props.type == 1">
      <div class="line"></div>
      <div class="font mr-8 ml-8">我加入的企业</div>
      <div class="line"></div>
    </div>
    <div class="switchItem cu-p mb-6" v-for="(el, ind) in state.sysCompanyList"
      :class="userInfo.companyId == el.companyId ? 'switchItemac' : ''"
      @click="userInfo.companyId == el.companyId ? '' : switchClick(el)">
      <div class="dp-f ai-c mb-6">
        <div class="mr-6">{{ el.companyName }}</div>
        <realname :is="el.isauthentication == 1" CSStype="3"></realname>
      </div>
      <div class="label">企业账号</div>
    </div>
    <div class="cu-p addCompany" @click="change">
      <img src="@/assets/img/account/createcompany.png" alt="">
      创建企业
        <!-- <oabutton CSStype=10 title="创建新企业" width=100 height=42 @buttonclick="change"/> -->
    </div>
  </div>
</template>
<script setup>
// isAuthentication 企业实名认证 0是未认证 1是已认证
// isCertification 个人实名认证状态 0是未认证 1是已认证

import { reactive, ref, computed, onMounted,unref,defineEmits} from "vue";
import { resetUserInfo, resetUserMenu } from "@/api/user"
import {clickUserCheck} from "@/utils/base/realname.js"
import { debounce } from "@/utils/util.js";
import { httpToken } from "@/utils/request";
import { handleMessage } from "@/utils/server/confirm.js"
import { getbutpermission } from "@/utils/base/permission.js"
import qs from "qs";
import realname from "@/components/icon/realname.vue"
const emit =defineEmits(['newCompany'])
const props = defineProps({
  type: {
    default: 1
  }
})
const loading = ref(false);//loading 显示
const userInfo = computed(() => {
  return JSON.parse(window.sessionStorage.user).user_info
})
const state = reactive({
  sysCompanyList:[]
})
const change=()=>{
  emit('newCompany')
  getCompanyall()
}
// 切换点击
const switchClick = debounce(function (el) {
  loading.value=true
  handleMessage('开始切换帐号!','success')
  let companyId = ''
  if (el)
    companyId = el.companyId
  httpToken({
    method: "post",
    url: '/admin/sys-user/changingOver',
    data:qs.stringify({
      companyId:companyId
    })
  }).then(res => {
    //切换当前登录状态
    resetUserInfo(() => {//刷新个人信息
      window.sessionStorage.removeItem("buttonPermissions");//移除按钮权限
      getbutpermission().then(res=>{
        resetUserMenu()//重新调取菜单信息
        loading.value=false
      }).catch(()=>{
        loading.value=false
      })//重新调取按钮权限信息
    },()=>{
      loading.value=false
    })
  }).catch(()=>{
    loading.value=false
  })
});
const getCompanyall=()=>{
    httpToken({
    method: "post",
    url: '/admin/sysuser-company/all',
  }).then(res => {
    console.log(res.data)
    state.sysCompanyList=res.data
  })
}
onMounted(()=>{
getCompanyall()

})
defineExpose({
  getCompanyall,
});
</script>
<style lang="scss" scoped >
@import '@/styles/color/value.scss';

.switchCenter {
  padding: 20px 0 0px 0px;
  font-size: 14px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #333330;
  max-height: 400px;

  .switchItem {
    padding: 8px 20px;

    .label {
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #666666;
    }
  }
  .addCompany{
    // width: 56px;
    display: flex;
    align-items: center;
    justify-content: center;
    height:49px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #666666;
  border-top:1px solid #EBEBEB ;
  }
  .switchItemac {
    // background: $active-alpha;
    background: #DCDCDC
  }

  .transition {
    .line {
      width: 85px;
      height: 1px;
      background: #EBEBEB;
    }

    .font {
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #666666;
    }
  }
}</style>